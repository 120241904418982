import { React, useState, useEffect} from 'react'

import Header from '../Components/Header/Header'
import Footer from '../Components/Footer/Footer'
import moon from '../../ImagesVideo/moon.webp'
import market from '../../ImagesVideo/market.svg';
import dao from '../../ImagesVideo/dao.svg';
import test from '../../ImagesVideo/test.svg';

import GradientButton from '../Components/Buttons/Gradient/GradientButton'

import AppCard from '../Components/Cards/App/AppCard'

import './Home.css'
import { useNetwork } from '../ConnectionContext'
import { SmartContractData } from '../../smartcontract/SmartContractData'


function Home() {
    const _network = useNetwork()

    const [isTestnet, setIsTestnet] = useState(false)
    
    useEffect(() => {
        const init = async () => {
            if (_network !== undefined) {

                if (_network.toString() === SmartContractData.networkTestnetId) {
                    setIsTestnet(true)
                }
            }
        }
        init()
    }, [_network])

    return (
        <>
            <Header />
            <div className='home-container'>
                <img className="home-moon" src={moon} alt='moon' />
                <div className="home-wrapper">
                    <div className="home-title">
                        <h2 className="heading title-1">Enter to the Lennyverse</h2>
                        <h2 className="heading title-2">
                            <span className="gradient-text">Build the story</span>
                        </h2>
                        <h2 className="heading title-3">Share with community</h2>
                    </div>
                    <div className="home-side">
                        <div className="arrow">
                            <div className="curve"></div>
                            <div className="point"></div>
                        </div>

                        <div className='heading-right'>
                            <GradientButton text={'JOIN THE COMMUNITY IDO NOW'} params={'/communityIDO'} link={true} />
                        </div>
                    </div>
                </div>
            </div>

            {/*<div className='home-container'>
                <img className="home-moon" src={moon} alt='moon' />
                <div className="home-wrapper">
                    <div className="home-title">
                        <h2 className="heading title-1">Enter to the Lennyverse</h2>
                        <h2 className="heading title-2">
                            <span className="gradient-text">Build the story</span>
                        </h2>
                        <h2 className="heading title-3">Share with community</h2>
                    </div>
                    <div className="home-side">
                        <div class="arrow">
                            <div class="curve"></div>
                            <div class="point"></div>
                        </div>

                        <div className='heading-right'>
                            <GradientButton text={'JOIN THE COMMUNITY IDO NOW'} params={'/communityIDO'} link={true} />
                        </div>
                    </div>
                </div>
            </div>*/}

            <div className='home-app-select-cont'>
                <h1 className="home-title-gradient">
                    TO BE RELEASED SOON
                </h1>

                <div className={`home-app-select-wrap ${isTestnet ? "testnet" : ""}`}>

                    <AppCard image={market}
                        description={'Discover Our Collection'}
                        title={'Market'} redirection={PopConstruction} linked={false} />

                    <AppCard image={dao}
                        description={'Build LennyVerse with us'}
                        title={'DAO'} redirection={PopConstruction} linked={false} />
                    
                    {isTestnet ?   <AppCard image={test}
                        description={'Try our applications'}
                        title={'Test'} redirection={'/claim'} linked={true}/> : ''}
                  

                </div>
            </div>
            <Footer />
        </>
    )
}

export default Home

function PopConstruction() {
    alert('This application is still in development -- Follow us on Discord to learn more ');
}