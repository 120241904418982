import { React } from 'react'
import Footer from '../Components/Footer/Footer'
import Header from '../Components/Header/Header'
import { Col } from 'react-bootstrap';
import astronaut from '../../ImagesVideo/astronaut_real.png'

import './DAO.css'


function DAO() {

 
    return (
        <>
            <Header />

            <div className="dao-container-one">
                <div className="row row-custom-dao">

                    <Col sm={8} lg={6} className="col-content-otr">
                        <div>
                            <h2 className="dao-title">Dentralized Autonomous</h2>
                            <h2 className="gradient-text dao">Community</h2>
                        </div>

                        <div>
                            Create, Vote for what you see in the LennyVerse. LennyVerse off-chain DAO is
                            an innovative way to invite the community to participate in the evolution of
                            the NFT Ecosystem.
                        </div>
                    </Col>

                    <Col sm={8} lg={6} className="col-img-otr-dao">
                        <div className="image-dao">
                            <img className="dao-spaceship animate-enter" src={astronaut} alt='spaceship' />
                        </div>
                    </Col>
                </div>
            </div>


            <Footer />
        </>

    )
}

export default DAO
