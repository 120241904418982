export const filterData = [{
    title: 'Status',
    values: [{
        name: "Buy Now"
      },
      {
        name: "On Auctions"
      },
      {
        name: "Has Offers"
      }
    ]
  },
  {
    title: 'Rarety',
    values: [{
        name: "Common"
      },
      {
        name: "Rare"
      },
      {
        name: "Epic"
      },
      {
        name: "Legendary"
      }
    ]
  }
];