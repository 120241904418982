import { React, useState, useEffect } from 'react';
// import { MdChevronLeft, MdChevronRight } from 'react-icons/md';

import { SmartContractData } from '../../../../smartcontract/SmartContractData';
import { useSigner, useProvider } from '../../../ConnectionContext';

import CollectionCard from '../../../Components/Cards/Collection/CollectionCard'
import './CollectionSlider.css'; 

const { ethers } = require('ethers');
const market = SmartContractData.testnet.market;

const CollectionSlider = (props) => {
    // const slideLeft = () => {
    //     var slider = document.getElementById("slider");
    //     slider.scrollLeft = slider.scrollLeft + 500;
    // }

    // const slideRight = () => {
    //     var slider = document.getElementById("slider");
    //     slider.scrollLeft = slider.scrollLeft - 500;
    // }

    const _provider = useProvider()
    const _signer = useSigner()

    const [allCollection, SetAllCollection] = useState([])

    useEffect(() => {
        const init = async () => {
            if (_signer !== undefined && _provider !== undefined) {
                const market_smt = new ethers.Contract(market.address, market.abi, _provider)
                const all_nft = await market_smt.getAllNFT()
                const filtered = all_nft.filter(function (value, index, arr) {
                    return index !== (props.id - 1);
                });
                SetAllCollection(filtered)
            }
        }
        init();
    }, [_provider, _signer, props.id])


    return (
        <>
            <div id="main-slider-container">
                {/* <MdChevronLeft size={70} className="collection-slider-icon left" onClick={slideRight} /> */}
                <div className="slider-collection-wrapper" id="slider">
                    {allCollection.map((data, index) =>
                        <CollectionCard/>
                    )}
    
                </div>
                {/* <MdChevronRight size={70} className="collection-slider-icon right" onClick={slideLeft} /> */}
            </div>
        </>

    )
}
export default CollectionSlider;