import React, {useState, useEffect}  from 'react';

import { Web3Storage, File } from 'web3.storage/dist/bundle.esm.min.js'
import {useSigner, useAccount} from '../../../ConnectionContext'
import GradientButton from '../../../Components/Buttons/Gradient/GradientButton'

import {db} from '../../../FirebaseConfig'
import {ref, get, update} from "firebase/database";
import {AiOutlineClose} from 'react-icons/ai'
import './PopUpProposal.css'

function PopUpProposal( props ) {

    const [value, setValue] = useState()

    function onChange(e) {
        if (!e.target.validity.patternMismatch) {
            setValue(e.target.value)
        }
    }

    const [vote, setVote] = useState([])
    const [oriValue, setOriValue] = useState([])

    const _signer = useSigner()
    const _account = useAccount()

    
    const VoteClick = async () =>{
        
        if(value < props.voter_.proposals[props.proposalId_].currentPower && _account!=='...') {

            vote['timestamp'] = Math.floor(Date.now() / 1000)
            vote['value'] = Math.floor(oriValue) + Math.floor(value)

            const _ref = props.proposalId_.toString() + '_' + props.nftId_.toString()  + '_' + value.toString()
            vote['ref'] = _ref
            const _refsig = await _signer.signMessage(_ref)
            vote['refSig'] = _refsig

            // send th file to IPFS
            const file_name = _account + '_' + _ref + '.json'
            const uploding = makeFileObjects(vote, file_name)
            const client = makeStorageClient()
            const cid = await client.put(uploding)
            vote['ipfs'] = `https://ipfs.io/ipfs/${cid}/${file_name}`

            // update the 
            const nftRef = ref(db, `proposals/${props.proposalId_}/nfts/${props.nftId_}`);
            const snapshot = await get(nftRef);
            const proposalinfo = snapshot.val()

            var newVoter;

            if(oriValue.toString() === '0'){
                newVoter = Math.floor(proposalinfo['numberOfVoter']) + 1
            } else {
                newVoter = Math.floor(proposalinfo['numberOfVoter'])
            }

            const newVote = Math.floor(proposalinfo['numberOfVotes']) + Math.floor(value)

            update(nftRef, { 'numberOfVoter': newVoter, 'numberOfVotes': newVote})

            const newPower = Math.floor(props.voter_.proposals[props.proposalId_].currentPower) - Math.floor(value)
            props.voter_.proposals[props.proposalId_].currentPower = newPower
            const newPowerUsed = Math.floor(props.voter_.proposals[props.proposalId_].PowerUsed) + Math.floor(value)
            props.voter_.proposals[props.proposalId_].PowerUsed = newPowerUsed

            props.voter_.proposals[props.proposalId_]['allvotes'][props.nftId_] = vote

            const final = {}
            final[_account] = props.voter_

            console.log(final)
            
            const voterRef = ref(db, `voters/`);

            update(voterRef, final)
        } else {
            alert( 'Not Enought Power');
        }
    }


        useEffect (() => {

            const init = async() => {

                if(props.voter_.proposals !== undefined && _account!== '...'){
                    
                    if (props.voter_.proposals[props.proposalId_]['allvotes'][props.nftId_] === undefined) {
                        setOriValue('0')
                        setVote({
                            "address": _account, "value": 0, "timestamp": "", 
                            "ref": "", "refsig": ""
                        })
                
                    } else {
                        setOriValue(props.voter_.proposals[props.props.proposalId_]['allvotes'][props.nftId_]['value'].toString())
                        setVote(props.voter_.proposals[props.proposalId_]['allvotes'][props.nftId_])
                    }
                }
                
            }
    
            init();
        }, [_account, props])
    
    

  return (
    <div className='popup-dao-main-cont'>
        <div className='popup-dao-cont'>
            
            <div className='dao-popup-header'>
                Vote For {props.nftdata.name}
            </div>

            <div className='popup-dao-exit' onClick={() => props.setOpening(false)}>
                <AiOutlineClose />
            </div>

            <p className='popup-dao-pwr'>
                You have is <b>{props.power_}</b> votes available
            </p>

            <div className="dao-popup-input">
                    $LENNY
                    <input className='dao-popup-input-value'
                        value={value || ''}
                        pattern="^[0-9]*$"
                        onChange={onChange} />
            </div>

            <GradientButton text={'Place your vote'} params={VoteClick} link={false} />

            <div className='popup-dao-desc'>
                    <h3 className="dao-popup-t-desc">
                        Description 
                    </h3>

                    <div className='dao-popup-line' style={{ width: '25%' }}> </div>

                    <p className='popup-dao-description-p'>
                        {props.nftdata.description}
                    </p>

            </div>

   

        </div>
    </div>
  );
}

export default PopUpProposal;


function makeStorageClient() {
    return new Web3Storage({ token: process.env.REACT_APP_WebStorageAPI })
}

function makeFileObjects(obj, filename) {

    const buffer = Buffer.from(JSON.stringify(obj));
  
    const files = [
      new File([buffer], filename)
    ]
    return files
}