import { React } from 'react';

import './CollectionCard.css'

function Collection1(props) {  
    return (
        <div className="collection-3">
          <div className="collection-frame" style={{ backgroundImage: `url(${collection1Data.frame})` }}>
            <div className="flex-col">
              <img className="punk" src={collection1Data.nft1} alt='nft'/>
              <img className="pirate_lenny" src={collection1Data.robot} alt='nft'/>
            </div>
            <img className="medieval" src={collection1Data.rocket} alt='nft'/>
          </div>
          <div className="description">
            <div className="flex-col-1">
              <div className="collection-2 sofiapro-medium-white-21px-22">
                <p className="sofiapro-semi-bold-white-21px">{collection1Data.spanText1}</p>
                <p className="sofiapro-medium-white-21px2">{collection1Data.spanText2}</p>
              </div>
              <div className="flex-row">
                <p className="address sofiapro-light-bon-jour-14px">
                  <p className="sofiapro-light-bon-jour-14px-2">{collection1Data.spanText3}</p>
                  <p className="sofiapro-light-bon-jour-16px">{collection1Data.spanText4}</p>
                </p>
                <div className="artist">
                  <img className="avt-8" src={collection1Data.avt8} alt='avatar'/>
                  <div className="kalai sofiapro-light-bon-jour-16px">{collection1Data.kalaI}</div>
                </div>
              </div>
            </div>
            <div className="like">
              <img className="icon-ionic-ios-heart-empty" src={collection1Data.iconIonicIosHeartEmpty} alt='icon'/>
              <div className="number sofiapro-regular-normal-white-16px">{collection1Data.number}</div>
            </div>
          </div>
        </div>
      );
  }

  const collection1Data = {
    frame: "https://anima-uploads.s3.amazonaws.com/projects/61e18b930eedab0725bf261f/releases/61e18d744388a308927f1073/img/trac--15@1x.png",
    nft1: "https://anima-uploads.s3.amazonaws.com/projects/61e18b930eedab0725bf261f/releases/61e969d8828ccec3883e2acd/img/punk-1@1x.png",
    robot: "https://anima-uploads.s3.amazonaws.com/projects/61e18b930eedab0725bf261f/releases/61e969d8828ccec3883e2acd/img/pirate-lenny-1@1x.png",
    rocket: "https://anima-uploads.s3.amazonaws.com/projects/61e18b930eedab0725bf261f/releases/61e969d8828ccec3883e2acd/img/medieval-1@1x.png",
    spanText1: "",
    spanText2: "Collection 1",
    spanText3: "10 Items · Created by",
    spanText4: "",
    avt8: "https://anima-uploads.s3.amazonaws.com/projects/61e18b930eedab0725bf261f/releases/61e969d8828ccec3883e2acd/img/avt-8-5@1x.png",
    kalaI: "@kala'i",
    iconIonicIosHeartEmpty: "https://anima-uploads.s3.amazonaws.com/projects/61e18b930eedab0725bf261f/releases/61e18d744388a308927f1073/img/icon-ionic-ios-heart-empty@1x.png",
    number: "846",
};



  export default Collection1;