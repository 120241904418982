import { React, useState, useEffect } from 'react'
import { Row, Col } from 'react-bootstrap';
import ReactPlayer from "react-player";
import { FaWallet } from 'react-icons/fa';

import Header from '../Components/Header/Header'
import Footer from '../Components/Footer/Footer'
import cidospaceship from '../../ImagesVideo/spaceship.png'
import avatar from '../../ImagesVideo/avatar.svg'

import GradientButton from '../Components/Buttons/Gradient/GradientButton'

import PopUp from '../Components/PopUp/PopUp'
import { SmartContractData } from '../../smartcontract/SmartContractData'
import { useSigner, useProvider, useAccount, useNetwork, numberWithCommas } from '../ConnectionContext'
import './CIDO.css';

const { ethers } = require('ethers')

function CommunityIDO() {
    const [value, setValue] = useState()

    function onChange(e) {
        if (!e.target.validity.patternMismatch) {
            setValue(e.target.value)
        }
    }

    const [cido, setCIDO] = useState(undefined)
    const [contToken, setContToken] = useState(undefined)

    const [toParticipate, setToParticipate] = useState(true)

    const [contributionBalance, setContributionBalance] = useState(undefined)
    const [contTokenBalance, setContTokenBalance] = useState(undefined)
    const [availableContribution, setAvailableContribution] = useState(undefined)
    const [userContribution, setUserContribution] = useState(undefined)

    const [participantAdd, setParticipantAdd] = useState(undefined)
    const [contributionAdd, setContributionAdd] = useState(undefined)

    const [lastHash, setLastHash] = useState(undefined)

    const [Participationed, setParticipationed] = useState('Participate')
    const [remove, setRemove] = useState('Remove')

    const [isOpen, setIsOpen] = useState(false)

    const _provider = useProvider()
    const _signer = useSigner()
    const _account = useAccount()
    const _network = useNetwork()

    useEffect(() => {
        const init = async () => {
            if (_signer !== undefined && _provider !== undefined && _network !== undefined && _account !== undefined) {

                var _cido;
                var _conttoken;

                if (_network.toString() === SmartContractData.networkMainnetId) {
                    _cido = SmartContractData.mainnet.cido
                    _conttoken = SmartContractData.mainnet.conttoken
                    setCIDO(_cido)
                    setContToken(_conttoken)

                } else if (_network.toString() === SmartContractData.networkTestnetId) {
                    _cido = SmartContractData.testnet.cido
                    _conttoken = SmartContractData.testnet.token
                    setCIDO(_cido)
                    setContToken(_conttoken)

                }

                const cido_smt = new ethers.Contract(_cido.address, _cido.abi, _provider)


                const _contributionBalance = await cido_smt.contributionBalance()
                setContributionBalance(numberWithCommas(_contributionBalance))

                const _availableContribution = await cido_smt.availableContribution()
                setAvailableContribution(numberWithCommas(_availableContribution))

                const userContributionContract = await cido_smt.CheckParticipantInfo(
                    _signer.getAddress(),
                )
                setUserContribution(
                    numberWithCommas(userContributionContract.contributionAmount),
                )

                const contToken_smt = new ethers.Contract(
                    _conttoken.address,
                    _conttoken.abi,
                    _provider,
                )

                const dec = await contToken_smt.decimals()

                const decimal_cont = ethers.BigNumber.from(10).pow(dec)

                const amount = ethers.BigNumber.from(
                    await contToken_smt.balanceOf(_signer.getAddress()),
                ).div(decimal_cont)
                setContTokenBalance(numberWithCommas(amount))

                cido_smt.on(
                    'ContributionConfirmed',
                    (participant, contributionAmount, event) => {
                        setParticipationed('Participate ✅')

                        setParticipantAdd(participant)
                        setContributionAdd(parseInt(contributionAmount.toHexString(), 16))

                        const newBalance = parseInt(userContribution)
                            + parseInt(contributionAmount.toHexString(), 16)

                        setUserContribution(newBalance)
                        setValue('');
                        setLastHash(event.blockHash)
                        setIsOpen(true)

                    },
                )

                cido_smt.on('RemovalConfirmed', (participant, removalAmount, event) => {
                    setRemove('Removed ✅')

                    setParticipantAdd(participant)
                    setContributionAdd(parseInt(removalAmount.toHexString(), 16))

                    const newBalance = parseInt(userContribution)
                        - parseInt(removalAmount.toHexString(), 16)

                    setUserContribution(newBalance)
                    setValue('')
                    setLastHash(event.blockHash)
                    setIsOpen(true)

                })
            }
        }
        init()
    }, [userContribution, _provider, _signer, _account, _network,])

    const approveClick = async (e) => {
        try {
            if (value) {
                e.preventDefault()
                setParticipationed('Attempt to participate ⏳')

                const contToken_smt = new ethers.Contract(
                    contToken.address,
                    contToken.abi,
                    _provider,
                )
                const decimal_bn = ethers.BigNumber.from(10).pow(18)
                const ValueDec = decimal_bn.mul(value)
                await contToken_smt
                    .connect(_signer)
                    .increaseAllowance(cido.address, ValueDec)

                return true
            }
        } catch (error) {
            alert(error.data['message'])
            return false
        }
    }

    const participationClick = async (e) => {
        const response = await approveClick(e);
        if (response) {
            try {
                if (value) {
                    e.preventDefault()

                    const cido_smt = new ethers.Contract(cido.address, cido.abi, _provider)
                    await cido_smt.connect(_signer).participate(value)

                }
            } catch (error) {
                setParticipationed('Participate')
                alert(error.data['message'])
            }
        }
    }

    const removalClick = async () => {
        try {
            if (value) {
                setRemove('Removing ⏳')

                const cido_smt = new ethers.Contract(cido.address, cido.abi, _provider)
                const tx = await cido_smt.connect(_signer).removeParticipation(value)

                setLastHash(await tx.wait())
            }
        } catch (error) {
            setRemove('Remove')
            alert(error.data['message'])
        }
    }

    return (
        <>
            <Header />
            <div className="wrapper">
                <div className="cido-container">
                    <div className="row row-custom">
                        <Col sm={8} lg={6} className="col-img-otr">
                            <div className="col-img-inr">
                                <div className="image">
                                    <img className="cido-spaceship animate-enter" src={cidospaceship} alt='spaceship' />
                                </div>
                            </div>
                        </Col>

                        <Col sm={8} lg={6} className="col-content-otr">
                            <div className="col-content-inr">
                                <div>
                                    <h2 className="cido-title">Welcome to Lenny</h2>
                                    <h2 className="cido-title bk">
                                        <span className="gradient-text cido">Community IDO</span>
                                    </h2>
                                </div>
                                <div className="cido-info">
                                    <Row>
                                        <Col><HeaderBox title={contributionBalance} paragraph={'Total Raised'} /></Col>
                                    </Row>
                                    <Row>
                                        <Col><HeaderBox title={availableContribution} paragraph={'Total Available'} /></Col>

                                        <Col><HeaderBox title={userContribution} paragraph={'Current Contribution'} /></Col>
                                    </Row>
                                </div>

                            </div>
                        </Col>
                    </div>
                </div>

                <div className='cido-cont-two'>
                    <Row>
                        <Col>
                            <div className='cido-youtube'>
                                <h3 className="title-bottom">
                                    WHAT IS THE COMMUNITY IDO ?
                                </h3>
                                <div className='line' style={{ width: '50%' }}> </div>

                                <div className='video'>
                                    <ReactPlayer
                                        className="react-player"
                                        url="https://www.youtube.com/watch?v=tskxzLotliE&t=3s&ab_channel=LennyVerses" controls={true} />
                                </div>
                            </div>
                        </Col>
                        <Col>
                            <div className='cido-interaction'>
                                <h3 className="title-bottom">
                                    BUY LENNY TOKEN
                                </h3>
                                <div className='line' style={{ width: '50%' }}> </div>

                                <div className='action'>
                                    <div className='action-grid'>
                                        <div onClick={() => setToParticipate(true)}> Participate </div>
                                        {toParticipate ? <div className='part-line' />
                                            : <div className='part-line-unselected' />}
                                    </div>

                                    <div className='action-grid'>
                                        <div onClick={() => setToParticipate(false)}> Remove Contribution </div>
                                        {!toParticipate ? <div className='part-line' />
                                            : <div className='part-line-unselected' />}
                                    </div>
                                </div>


                                <div className='user'>
                                    <img src={avatar} alt="Avatar" />

                                    <div className='text-address'>
                                        <p className='connect-text'>
                                            Connected Address
                                        </p>

                                        <p className='address-text'>
                                            {_account}
                                        </p>
                                    </div>
                                </div>
                                <p className='caution'>You can only contribute up to a maximum of 5.000 USDC</p>

                                <div className='balance'>
                                    <div className='balance-text'>
                                        <FaWallet />
                                        Your Current Balance
                                    </div>

                                    <div className='balance-amount'>
                                        {contTokenBalance} USDC
                                    </div>
                                </div>

                                <div className='cont-function'>
                                    <div className="input-box">
                                        <input className='input-value'
                                            value={value || ''}
                                            pattern="^[0-9]*$"
                                            onChange={onChange} />
                                    </div>

                                    {toParticipate ?
                                        <GradientButton text={Participationed} params={participationClick} link={false} />
                                        :
                                        <GradientButton text={remove} params={removalClick} link={false} />
                                    }

                                </div>

                            </div>
                        </Col>
                    </Row>
                </div>
            </div>




            <Footer />

            <PopUp trigger={isOpen} setTrigger={setIsOpen} >

                <h3 className='popup-title'> Successful Transaction </h3>
                <br />
                <p> Contributor address : {participantAdd} </p>
                <br />
                <p> Amount Added : {contributionAdd} </p>
                <br />
                <p> Tx Hash : {lastHash} </p>

            </PopUp>
        </>
    )
}

export default CommunityIDO;

const HeaderBox = (props) => {

    return (
        <div className="header-stat-box">
            <div className="text-box">
                <h3>
                    {props.title} USDC
                </h3>

                <p className='p-box'>
                    {props.paragraph}
                </p>
            </div>
        </div>
    );
};

