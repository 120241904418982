import { React } from 'react';
import "./SocialButton.css";

const SocialButton = (props) => {

    return (
        <div className="btn-container">
            <a className="btn-social" href={props.params} target="_blank" rel="noopener noreferrer">
                {props.icon}
            </a>
        </div>
    );
};

export default SocialButton;