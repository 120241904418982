import { React, useState, useEffect } from 'react';
import { ImPower } from 'react-icons/im';
import { AiOutlineHeart, AiFillHeart } from 'react-icons/ai';
import { FiUsers } from 'react-icons/fi';
import axios from 'axios';

import avatar from '../../../../ImagesVideo/avatar.svg';
import './NFTCard.css';


const NFTCard = ({ data, like, hype, params, link, setId, setOpen, market }) => {
    const [metadata, setMetadata] = useState([]);
    const [isLiked, setLiked] = useState(false);

    useEffect(() => {
        const init = async () => {
            const meta = await axios.get(data.tokenURI);
            setMetadata(meta.data);
        }

        init();
    }, [data])

    const ClickDAO = () => {
        if (setId !== undefined) {
            setId(data.id);
            setOpen(true);
        }
    }


    const ConditionalWrapper = ({ condition, wrapperlink, wrapperfct, children }) =>
        condition ? wrapperlink(children) : wrapperfct(children);

    return (
        <ConditionalWrapper condition={link}
            wrapperlink={children => <a className="card-container" href={params}> {children} </a>}
            wrapperfct={children => <div className="card-container" onClick={ClickDAO}> {children} </div>}
        >
            <div className="card-interaction">
                {hype ? <div className="card-btn left hype">
                    <span><ImPower size={12} /> HYPE </span>
                </div> : <></>}

                {like ?
                    <div
                        className="card-btn right stat"
                        onClick={() => setLiked(!isLiked)}>
                        {isLiked ? <span><AiFillHeart className="red" size={17} /> 182</span>
                            : <span><AiOutlineHeart size={17} /> 182</span>}
                    </div> :
                    <div className="card-btn right stat">
                        <span ><FiUsers size={15} /> 78</span>
                    </div>
                }
            </div>

            <div className="card-image">
                <img src={metadata.image} alt="nftname" />
            </div>

            <div className="card-title">
                <h5>{metadata.name}</h5>
            </div>

            <div className="card-description">
                <div className="author">
                    <div className="avatar">
                        <img src={avatar} alt="Avatar" />
                    </div>
                    <div className="info">
                        Created By
                        <h6>
                            <a href=".">Kala'i Shigetomi</a>
                        </h6>
                    </div>
                </div>
                <div className="bid">
                    {(market) ?
                        <>
                            Current Bid
                            <h5>1.58 ETH</h5>

                        </> :
                        <>
                            Votes
                            <h5>9845</h5>
                        </>}
                </div>
            </div>
        </ConditionalWrapper>
    );
};



export default NFTCard;