import React, { useState, useEffect, useContext} from 'react'
import {SmartContractData} from '../smartcontract/SmartContractData'
const {ethers} = require('ethers')

const networkAllowed = SmartContractData.networkIdAllowed

const AccountContext = React.createContext()
const NetworkContext = React.createContext()
const MetamaskContext = React.createContext()
const ConnectionContext = React.createContext()
const GoodNetworkContext = React.createContext()
const AuthenticateContext = React.createContext()
const SignerContext = React.createContext()
const ProviderContext = React.createContext()

export function useAccount() {
    return useContext(AccountContext)
}

export function useSigner() {
    return useContext(SignerContext)
}

export function useProvider() {
    return useContext(ProviderContext)
}

export function useNetwork() {
    return useContext(NetworkContext)
}

export function useMetamask() {
    return useContext(MetamaskContext)
}

export function useConnection() {
    return useContext(ConnectionContext)
}

export function useGoodNetwork() {
    return useContext(GoodNetworkContext)
}

export function useAuthenticate() {
    return useContext(AuthenticateContext)
}

function ConnectionProvider({children}){

    const [provider, setProvider] = useState(undefined)
    const [signer, setSigner] = useState(undefined)

    const [metamaskInstalled, setMetamaskInstalled] = useState(undefined)
    const [networkId, setNetworkId] = useState(undefined)
    const [account, setAccount] = useState('...')

    const [goodNetwork, setGoodNetwork] = useState(undefined)
    const [authenticated, setAuthentification] = useState(false)


    const toConnect = async () => {

        const newProvider = new ethers.providers.Web3Provider(window.ethereum, 'any');
        await newProvider.send("eth_requestAccounts", []);
        const newSigner = newProvider.getSigner();

        setProvider(newProvider)
        setSigner(newSigner)

        const newnetwork = parseInt(await newSigner.getChainId())
        setNetworkId(newnetwork)
        setGoodNetwork(networkAllowed.includes(newnetwork) ? true : false);
        setAccount(await newSigner.getAddress())
        setAuthentification(true)
    }

    useEffect (() => {

        const init = async() => {
            if (typeof window.ethereum.isMetaMask !== 'undefined') {
                setMetamaskInstalled(true);
            }

            window.ethereum._metamask.isUnlocked().then((result) => {
                if (result) toConnect();
            });
        }


        init();
    }, [])

    return (
        <ConnectionContext.Provider value={toConnect}>
            <ProviderContext.Provider value={provider}>
                <SignerContext.Provider value={signer}>
                    <MetamaskContext.Provider value={metamaskInstalled}>
                        <NetworkContext.Provider value={networkId}>
                            <GoodNetworkContext.Provider value={goodNetwork}>
                                <AuthenticateContext.Provider value={authenticated}>
                                    <AccountContext.Provider value={account}>
                                            {children}
                                    </AccountContext.Provider>
                                </AuthenticateContext.Provider>
                            </GoodNetworkContext.Provider>
                        </NetworkContext.Provider>          
                    </MetamaskContext.Provider>
                </SignerContext.Provider>
            </ProviderContext.Provider>

        </ConnectionContext.Provider>
    )
}

export default ConnectionProvider;


export const numberWithCommas = (x) => {
   x = x.toString();
   var pattern = /(-?\d+)(\d{3})/;
   while (pattern.test(x))
       x = x.replace(pattern, "$1,$2");
   return x;
}

