import { React, useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import Footer from '../Components/Footer/Footer'
import Header from '../Components/Header/Header'
import { Col, Row } from 'react-bootstrap';
import axios from 'axios'

import { useSigner, useProvider, useAccount } from '../ConnectionContext'

import NFTCard from '../Components/Cards/NFT/NFTCard'

import PopUpProposal from './Components/PopUpProposal/PopUpProposal'
import DropdownItem from './Components/DropDown/DropDown'
import SideBox from '../Components/SideBox/SideBox'
import { db } from '../FirebaseConfig'
import { ref, get } from "firebase/database"
import { FaSuperpowers } from 'react-icons/fa'

import CollectionSlider from './Components/CollectionSlider/CollectionSlider';

import { MdPhotoCamera } from 'react-icons/md'
import { AiOutlineSend } from 'react-icons/ai'
import { FiPlus, FiMinus } from 'react-icons/fi'

import {SmartContractData} from '../../smartcontract/SmartContractData'

import './Proposal.css'
import './Components/DropDown/DropDown.css'

const {ethers} = require('ethers')

const Moralis  = require('moralis');

const serverUrl = process.env.REACT_APP_MoralisServerURL;
const appId = process.env.REACT_APP_MoralisAppId;
Moralis.start({ serverUrl, appId });

const token = SmartContractData.testnet.token

function Proposal() {

    const { id } = useParams()

    const _provider = useProvider()
    const _signer = useSigner()
    const _account = useAccount()

    const [proposalData, setProposalData] = useState({'snapshot': 0, 'endDate': 0, 'totalVotes': 0})

    const [openPopUp, setOpenPopUp] = useState(false)
    const [selectedId, setSelectedId] = useState('0')

    const [metadata, setMetadata] = useState([])
    const [nftNetadata, setNFTMetadata] = useState({})
    const [ranking, setRanking ] = useState([])

    const [voter, setVoter] = useState([])
    const [power, setPower] = useState(0)

    const [topVoters, setTopVoters] = useState([])

    const [clicked, setClicked] = useState({'1': false, '2': false})
    const toggle = index => {
        if (index === '1'){
            if(clicked['1'] === index) {
                return setClicked({'1': null, '2': clicked['2']})
            }
            setClicked({'1': index, '2': clicked['2']})
        }
        else{
            if(clicked['2'] === index) {
                return setClicked({'1': clicked['1'], '2': null})
            }
            setClicked({'1': clicked['1'], '2': index})
        }
    }

    useEffect(() => {

        const init = async () => {

            if(_account!=='...' && _signer !== undefined && _provider !== undefined){
                const poposalsInfo = ref(db, `proposals/${id}`);
                const votersInfo = ref(db, 'voters')

                const snapshotOne = await get(poposalsInfo);
                const votersSnapShot = await get(votersInfo)

                const _data_ = snapshotOne.val()
                const voters_data = votersSnapShot.val()

                const listMeta = []
                const listRanking = []

                const listTopVoters = []

                const poposalMetadata = {}

                var totalVotes = 0

                for (const nft in _data_.nfts) {
                    const data = { 'id': nft, 'tokenURI': _data_.nfts[nft].infoURI, 
                                    'numberOfVoter': _data_.nfts[nft].numberOfVoter,
                                    'numberOfVotes': _data_.nfts[nft].numberOfVotes}
                    listMeta.push(data)
                    listRanking.push(data)
                    const meta_ = await axios.get(_data_.nfts[nft].infoURI)
                    poposalMetadata[nft] = meta_.data
                    totalVotes += _data_.nfts[nft].numberOfVotes
                }

                for (const voter in voters_data){
                    const voterInfo = ref(db, `voters/${voter}/proposals/${id}`)
                    const voterSnapShot = await get(voterInfo)
                    const voter_data = voterSnapShot.val()
                    listTopVoters.push({'address': voter, 'PowerUsed': voter_data.PowerUsed})
                }

                listTopVoters.sort((a, b) => {
                    return b.PowerUsed - a.PowerUsed;
                })

                setTopVoters(listTopVoters.slice(0, 5))

                setNFTMetadata(poposalMetadata)

                setMetadata(listMeta)

                listRanking.sort((a, b) => {
                    return b.numberOfVotes - a.numberOfVotes;
                })
                setRanking(listRanking.slice(0, 5))

                setProposalData({
                    'snapshot': _data_.information.snapshot, 
                    'endDate': _data_.information.endDate,
                    'totalVotes': totalVotes
                })

                // voter information
                const voterRef = ref(db, `voters/${_account}`);
                const snapshotTwo = await get(voterRef);
                const voterinfo = snapshotTwo.val();

                var currentinfo;
                if (voterinfo === null) {
                    const base = {'address': _account,  'proposals': {} }
                    setVoter(base)
                    currentinfo = base
                } else { 
                    setVoter(voterinfo)
                    currentinfo = voterinfo
                }


                if (currentinfo.proposals[id] === undefined) {
                    // Extract the Voting Power
                    const options = { chain: "0x61", address: _account, to_block: 16070992}
                    const snapInfo = await Moralis.Web3API.account.getTokenBalances(options)
                
                    var _power;
                    for(const clef in snapInfo){
                        if(snapInfo[clef].token_address.toUpperCase() === token.address.toUpperCase()){
                            const decimal_bn = ethers.BigNumber.from(10).pow(18)
                            const newBalance = ethers.BigNumber.from(snapInfo[clef].balance).div(decimal_bn)
                            _power = Math.floor(newBalance)
                            setPower(_power)
                        }
                    }

                    const votingProposal = {
                        "startPower": _power,
                        "currentPower": _power,
                        "PowerUsed": 0,
                        'allvotes': {}
                    }
                    currentinfo.proposals[id] = votingProposal

                    
                } else {
                    setPower(currentinfo.proposals[id]['currentPower'])
                }

            }               
        }

        init();
    }, [_provider, _signer, _account, id])

    return (
        <>

            <Header />

            <div className="dao-container-two">

                <div className="dao-collection">
                    <Row>
                        <Col xl={3} lg={3} md={12}>
                            <SideBox icon={<FaSuperpowers />} title={'Voting Power'} content={power}/>
                            <SideBox icon={<FaSuperpowers />} title={'Total Votes'} content={proposalData.totalVotes}/>
                            <SideBox icon={<MdPhotoCamera />} title={'Snapshot'} content={proposalData.snapshot}/>
                            <SideBox icon={<AiOutlineSend />} title={'End'} content={proposalData.endDate}/>
                        </Col>

                        <Col xl={9} lg={9} md={12}>

                            <div className="explore-container">
                                {metadata.map((data, index) =>
                                    <NFTCard data={data} key={index}  link={false} setId={setSelectedId} setOpen={setOpenPopUp}/>
                                )}
                            </div>

                        </Col>

                    </Row>
                </div>

            </div>

            <div className="stats-dropdown">
                <div className='stats-container'>
                    <div className='wrap' onClick={() => toggle('1')} key={'1'}>
                        <p>Live ranking</p>
                        <span>{clicked['1'] === '1' ? <FiMinus /> : <FiPlus />}</span>
                    </div>

                    <div className="gradient-line"></div>
                    
                    <div className='dropdown-item'>
                        {ranking.map((item, index) => {
                            return (
                                <> 
                                {clicked['1'] === '1' ? (
                                    <DropdownItem name={nftNetadata[item.id].name} index={index} nbVotes={item.numberOfVotes} totalVotes={proposalData.totalVotes} image={nftNetadata[item.id].image}/>
                                    ) : null}
                                </>
                            )
                        })}
                    </div>
                </div>

                <div className='stats-container'>
                    <div className='wrap' onClick={() => toggle('2')}  key={'2'}>
                        <p>Top 5 voters</p>
                        <span>{clicked['2'] === '2' ? <FiMinus /> : <FiPlus />}</span>
                    </div>

                    <div className="gradient-line" />
                    
                    <div className='dropdown-item'>
                        {topVoters.map((item, index) => {
                            return (
                                <> 
                                {clicked['2'] === '2' ? (
                                    <DropdownItem name={item.address} index={index} nbVotes={item.PowerUsed} totalVotes={proposalData.totalVotes}  key={index}/>
                                    ) : null}
                                </>
                            )
                        })}
                    </div>
                </div>

                <div className='slides-container'>
                    <div className='wrap' onClick={() => toggle('2')}  key={'2'}>
                            <p>Dive into other collections</p>
                    </div>

                    <div className="gradient-line" />

                </div>

                <CollectionSlider />

            </div>
            

            <Footer />

            {openPopUp ? <PopUpProposal setOpening={setOpenPopUp} nftdata={nftNetadata[selectedId]} 
            power_={power} voter_={voter} proposalId_={id} nftId_={selectedId}/> : ''}
        </>

    )
}

export default Proposal
