import { React, useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { FaBook, FaSketch } from 'react-icons/fa';
import { RiArrowDownSLine } from 'react-icons/ri';
import { MdAttachMoney } from 'react-icons/md';

import Header from '../Components/Header/Header';
import NFTCard from '../Components/Cards/NFT/NFTCard';
import Dropdown from '../Components/Dropdown/Dropdown';
import SideBox from '../Components/SideBox/SideBox';
import Footer from '../Components/Footer/Footer';

import { SmartContractData } from '../../smartcontract/SmartContractData';
import { useSigner, useProvider } from '../ConnectionContext';

import './Market.css';
import moon from '../../ImagesVideo/moon.webp';
import avatar from '../../ImagesVideo/avatar.svg';
import { filterData } from './FilterContent'; // just for testing frontend filter

const { ethers } = require('ethers');
const market = SmartContractData.testnet.market

function Market() {
    const _provider = useProvider()
    const _signer = useSigner()

    const [AllNFT, setAllNFT] = useState([])

    useEffect(() => {
        const init = async () => {
            if (_signer !== undefined && _provider !== undefined) {
                const market_smt = new ethers.Contract(market.address, market.abi, _provider)
                const all_nft = await market_smt.getAllNFT()
                setAllNFT(all_nft)
            }
        }
        init();
    }, [_provider, _signer])

    return (
        <>
            <Header />
            <div className="header-section">
                <img className="market-moon" src={moon} alt='moon' />

                <div className="header-wrapper">
                    <div className="header-content">
                        <div className="heading-content">
                            <h2 className="heading">Enter to the</h2>
                            <h2 className="heading">
                                <span className="gradient-text">Chapiter I</span> of
                            </h2>
                            <h2 className="heading story">The Lenny Story</h2>

                            <div className="heading-btn-market">
                                <a href="." className="button">
                                    <FaBook /> Read the story
                                </a>
                                <a href="." className="button">
                                    <RiArrowDownSLine /> See collection
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="market-section">
                <div className="market-container">
                    <Row>
                        <Col className="mobile-center" xl={3} lg={3} md={12}>
                            <div className="side-bar">
                                {filterData.map(({ title, content, values }) => (
                                    <Dropdown
                                        key={title}
                                        title={title}
                                        content={content}
                                        values={values} />
                                ))}


                            </div>
                            <div className="collection-info">
                                <div className="title">Collection information</div>
                                <div className="content">
                                    <div className="author">
                                        <img src={avatar} alt="Avatar" />
                                        <div className="info">
                                            Created By
                                            <h6><a href=".">Kala'i Shigetomi</a></h6>
                                        </div>
                                    </div>
                                    <SideBox
                                        icon={<FaSketch size={14} />}
                                        title={'Items'}
                                        content={'10'} />
                                    <SideBox
                                        icon={<MdAttachMoney size={17} />}
                                        title={'Max bid'}
                                        content={'5 ETH'} />
                                </div>
                            </div>
                        </Col>
                        <Col xl={9} lg={9} md={12} className="padding-0">
                            <div className="explore-container">
                                {AllNFT.map((data, index) =>
                                    <NFTCard
                                        data={data}
                                        key={index}
                                        market={true}
                                        link={true}
                                        params={`./market/item/${data.tokenId}`}
                                        like={true}
                                        hype={true} />
                                )}
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Market;