import { initializeApp } from 'firebase/app';
import { getDatabase } from "firebase/database";

// Set the configuration for your app
const firebaseConfig = {
  apiKey: process.env.REACT_APP_APIKEY_Firebase,
  authDomain: process.env.REACT_APP_Auth_Domain,
  databaseURL: process.env.REACT_APP_Database_URL,
  storageBucket: process.env.REACT_APP_Storage_Bucket
};

const app = initializeApp(firebaseConfig);

// Get a reference to the database service
export const db  = getDatabase(app);