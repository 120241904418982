import "./SideBox.css";

const SideBox = (props) => {

    return (
        <div className="side-box-dao">
            <div className="dao-box-wrapper">
                <div className='dao-box-left'>
                    {props.icon} {props.title}
                </div >

                <div className='dao-box-right'>
                    {props.content}
                </div>
            </div>
        </div>
    );
};

export default SideBox;