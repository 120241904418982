import { React, useEffect, useState} from 'react';
import { FaTrophy } from 'react-icons/fa'
import './DropDown.css'


const DropdownItem = ({index, name, nbVotes, totalVotes, image}) => {
    const [color, setColor] = useState('')
    const [percentage, setPercentage] = useState('')

    var per = 100*(nbVotes / totalVotes)
    per = per.toFixed(1).toString() + ' %'


    useEffect(() => {
        const init = async () => {
            setPercentage(per)

            if(index === 0){
                setColor('gold')
            }
            else if (index === 1) {
                setColor('silver')
            }
            else if (index === 2) {
                setColor('brown')
            }
        }

        init();
    }, [index, per])

    return (
            <li>
                <p>{index + 1}</p>
                <p>{name}</p>
                <p>{nbVotes.toLocaleString('en')}</p>
                <p><FaTrophy color={color}/></p>
                <p>{percentage}</p>

                {image ? (<img src={image} alt='nft'/>) : null}
                
            </li>

    );
  }

export default DropdownItem;