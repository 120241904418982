import {React} from 'react'

import logo from '../../../ImagesVideo/LennyFaceLogo.webp';
import {AiOutlineClose} from 'react-icons/ai'

import './PopUp.css';

export function PopUp(props) {

    return (props.trigger) ? (
        <div className='popup'>
            <div className='popup-inner'>
                <img className='img-logo'  src={logo} alt="Logo" />

                <button className='close-btn' onClick = {()=> props.setTrigger(false)}>
                    <AiOutlineClose />
                </button>
                {props.children}
            </div>
        </div>

    ) : "";
}

export default PopUp

