import { React, useState, useEffect } from 'react';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';

import { SmartContractData } from '../../../smartcontract/SmartContractData';
import { useSigner, useProvider } from '../../ConnectionContext';

import NFTCard from '../Cards/NFT/NFTCard'
import './CardSlider.css'; // Card slider style

const { ethers } = require('ethers');
const market = SmartContractData.testnet.market;

const CardSlider = (props) => {
    const slideLeft = () => {
        var slider = document.getElementById("slider");
        slider.scrollLeft = slider.scrollLeft + 500;
    }

    const slideRight = () => {
        var slider = document.getElementById("slider");
        slider.scrollLeft = slider.scrollLeft - 500;
    }

    const _provider = useProvider()
    const _signer = useSigner()

    const [AllNFT, setAllNFT] = useState([])

    useEffect(() => {
        const init = async () => {
            if (_signer !== undefined && _provider !== undefined) {
                const market_smt = new ethers.Contract(market.address, market.abi, _provider)
                const all_nft = await market_smt.getAllNFT()
                const filtered = all_nft.filter(function (value, index, arr) {
                    return index !== (props.id - 1);
                });
                setAllNFT(filtered)
            }
        }
        init();
    }, [_provider, _signer, props.id])

    return (
        <div id="main-slider-container">
            <MdChevronLeft size={40} className="slider-icon left" onClick={slideRight} />
            <div className="slider-wrapper" id="slider">
                {AllNFT.map((data, index) =>
                    <NFTCard
                        data={data}
                        key={index}
                        like={true}
                        hype={true}
                        market={true} />
                )}
            </div>
            <MdChevronRight size={40} className="slider-icon right" onClick={slideLeft} />
        </div>
    )
}
export default CardSlider;