import './App.css';

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import ConnectionProvider from './pages/ConnectionContext'

import Home from './pages/Home/Home';
import CommunityIDO from './pages/CIDO/CIDO';
import Giveaway from './pages/Giveaway/Giveaway';
import Claim from './pages/Claim/Claim';
import Market from './pages/Market/Market';
import NFTItem from './pages/Item/NFTItem';

import DAO from './pages/DAO/DAO';
import Proposal from './pages/Proposal/Proposal';


function App() {

  return (
    <Router>
      <Switch>

        <ConnectionProvider>

          <Route path="/" component={Home} exact />
          <Route path="/communityIDO" component={CommunityIDO} exact />
          <Route path="/giveaway" component={Giveaway} exact />
          <Route path="/claim" component={Claim} exact />
          <Route path="/market" component={Market} exact />
          <Route path="/market/item/:id" component={NFTItem} exact />
          <Route path="/dao" component={DAO} exact />
          <Route path="/dao/Proposal/:id" component={Proposal} exact />

        </ConnectionProvider>

      </Switch>
    </Router>

  );
}

export default App;
