import {React} from 'react'
import GradientButton from '../../Buttons/Gradient/GradientButton'
import './AppCard.css'

function AppCard( props ) {


    return (
        <div className='card-cont'>

            <div className='card-img-wrap'>
                <img className='card-app-img' src={props.image} alt={props.title}/>
            </div>

            <div className='card-text-wrap'>
                <div className='app-card-decription'> {props.description} </div>
            </div>

            <div className='card-app-btn-wrap'>
                <GradientButton text={props.title} params={props.redirection} link={props.linked}/>
            </div> 

        </div>
    )
}

export default AppCard
