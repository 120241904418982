import { React, useState } from 'react';

import { RiArrowDownSLine, RiArrowRightSLine } from "react-icons/ri";

import './Dropdown.css';

const Dropdown = ({ title, content, values }) => {
    const [isActive, setIsActive] = useState(false);

    const [checkedState, setCheckedState] = useState(
        new Array(values.length).fill(false)
    );



    const handleOnChange = (position) => {
        const updatedCheckedState = checkedState.map((item, index) =>
            index === position ? !item : item
        );

        setCheckedState(updatedCheckedState);
    };

    return (
        <div className="filter-item">
            <div className="filter-title" onClick={() => setIsActive(!isActive)}>
                <div>{title}</div>
                <div>
                    {isActive ? <RiArrowDownSLine /> : <RiArrowRightSLine />}
                </div>
            </div>
            {isActive &&
                <div className="filter-content">
                    {values.map(({ name }, index) => {
                        return (
                            <div key={index}>
                                <label>
                                    {name}
                                    <input
                                        className="hidden-xs-up"
                                        type="checkbox"
                                        id={`cbx`}
                                        name={name}
                                        value={name}
                                        checked={checkedState[index]}
                                        onChange={() => handleOnChange(index)} />
                                    <span className="btn-checkbox"></span>

                                </label>
                            </div>
                        );
                    })}
                </div>}
        </div>
    );
};

export default Dropdown;