import { React, useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useParams } from 'react-router-dom'
import axios from 'axios';
import moment from "moment";
import { BiTimeFive, BiDollar } from 'react-icons/bi';
import { GiPayMoney } from 'react-icons/gi';

import './NFTItem.css'; // page style file
import NFTFrame from '../../ImagesVideo/NFTFrame.png';
import Avatar from '../../ImagesVideo/avatar.svg';
import Avatar2 from '../../ImagesVideo/avt-3.jpg';

import Header from '../Components/Header/Header';
import GradientButton from '../Components/Buttons/Gradient/GradientButton';
import CardSlider from '../Components/Slider/CardSlider';
import Footer from '../Components/Footer/Footer';

import { SmartContractData } from '../../smartcontract/SmartContractData';
import { useSigner, useProvider, numberWithCommas } from '../ConnectionContext';

const { ethers } = require('ethers');

const market = SmartContractData.testnet.market;
const contToken = SmartContractData.testnet.token;

function NFTItem() {
    const [contractAddress, setContractAddress] = useState('');

    const { id } = useParams();
    const [name, setName] = useState('');
    const [image, setImage] = useState('');
    const [owner, setOwner] = useState('');
    const [minPrice, setMinPrice] = useState('');
    const [lastBid, setLastBid] = useState('');
    const [lastBidNumber, setLastBidNumber] = useState('');
    const [description, setDescription] = useState('');

    const [balance, setBalance] = useState('');

    const [time, setTime] = useState('');

    const _provider = useProvider();
    const _signer = useSigner();

    console.log(owner)
    console.log(minPrice)
    console.log(lastBid)
    console.log(lastBidNumber)
    console.log(description)
    console.log(balance)
    console.log(time)
    console.log(contractAddress)



    useEffect(() => {

        const init = async () => {

            if (_signer !== undefined && _provider !== undefined && id !== undefined) {

                const decimal_bn = ethers.BigNumber.from(10).pow(18);

                const market_smt = new ethers.Contract(market.address, market.abi, _provider);
                const contToken_smt = new ethers.Contract(contToken.address, contToken.abi, _provider);

                const nftinf = await market_smt.NFTtoInfo(id);
                setContractAddress(nftinf.nftContract);

                const auctinf = await market_smt.AuctiontoInfo(id);
                setLastBid(numberWithCommas(auctinf.lastbid));
                setLastBidNumber(numberWithCommas(auctinf.lastBidNumber));

                const auctnft = await market_smt.AuctiontoNFT(id);
                setOwner(auctnft.owner);
                setTime(moment.unix(auctnft.endAuction).format("YYYY-MM-DD HH:mm:ss"));
                setMinPrice(numberWithCommas(auctnft.minPrice));

                const meta = await axios.get(nftinf.tokenURI);
                setName(meta.data.name);
                setImage(meta.data.image);
                setDescription(meta.data.description);

                const bal = await contToken_smt.balanceOf(_signer.getAddress());
                setBalance(numberWithCommas(bal.div(decimal_bn)));
            }
        }

        init();

    }, [_provider, _signer, id, name])

    return (
        <>
            <Header />

            <div className="item-wrapper">
                <div className="item-container">
                    <Row className="item-row">
                        <Col>
                            <div className="item-preview">
                                <img className="frame" src={NFTFrame} alt='NFT' />
                                <img className="nft" src={image} alt='NFT' />
                            </div>
                        </Col>

                        <Col>
                            <div className="item-content">
                                <div className="item-description">
                                    <h2 className="title">
                                        {name}
                                    </h2>
                                    <div className="item-wrapper">
                                        <UserBox
                                            title={'Owned By'}
                                            img={Avatar2}
                                            user_name={'0xAb5801a7D398351...'} />
                                        <UserBox
                                            title={'Created By'}
                                            img={Avatar}
                                            user_name={'Kala\'i Shigetomi'} />
                                    </div>
                                    <p className="item-story">
                                        This Space suit will cut any dog into multiple saussages. Lorem ipsum dolor sit amet,
                                        consectetur adipiscing elit. Ut auctor sit amet quam eget lobortis. In orci libero,
                                        malesuada quis ultricies non, porttitor ac ante. Quisque ac consectetur justo.
                                        <br /><br />
                                        Pellentesque interdum suscipit convallis. Praesent lectus lorem, vehicula id viverra quis,
                                        posuere eu lacus. Aenean iaculis pulvinar mauris, ut hendrerit diam molestie eget.
                                        Morbi sit amet nisi et arcu luctus volutpat ac vel ligula. Nulla ultrices mollis massa.
                                        Nunc facilisis varius elit in rutrum. Suspendisse sit amet iaculis massa, id accumsan leo.
                                    </p>
                                    <div className="item-wrapper">
                                        <InfoBox
                                            icon={<BiTimeFive size={17} />}
                                            title={'Sales ends in'}
                                            content={'4:59:10:20'} />
                                        <InfoBox
                                            icon={<BiDollar size={17} />}
                                            title={'Current Bid'}
                                            content={'0.5 ETH'} />
                                    </div>

                                    <div className="item-button">
                                        <div className="item-input">
                                            <GiPayMoney className="icon" />
                                            <input className='input-value'
                                                pattern="^[0-9]*$" />
                                        </div>
                                        <GradientButton text={"Place a bid"} link={false} />
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>

                <CardSlider id={id} />
            </div>

            <Footer />
        </>
    )
}

const InfoBox = (props) => {
    return (
        <div className="item-box">
            <div className="item-box-wrapper">
                <div className='item-left'>
                    {props.icon} {props.title}
                </div >

                <div className='item-right'>
                    {props.content}
                </div>
            </div>
        </div>
    );
}

const UserBox = (props) => {
    return (
        <div className="user-box">
            <div className="user-content">
                <img src={props.img} alt="Avatar" />
                <div className="user-desc">
                    <h6>{props.title}</h6>
                    <h5>{props.user_name}</h5>
                </div>
            </div>
        </div>
    );
}

export default NFTItem;