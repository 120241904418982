import { React, useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import giveaway from '../../ImagesVideo/giveaway.png'

import GradientButton from '../Components/Buttons/Gradient/GradientButton'

import PopUp from '../Components/PopUp/PopUp'

import Header from '../Components/Header/Header'
import Footer from '../Components/Footer/Footer'

import {useSigner, useProvider, numberWithCommas, useAccount, useNetwork} from '../ConnectionContext'

import { SmartContractData } from '../../smartcontract/SmartContractData'

import './Giveaway.css'


const {ethers} = require('ethers')

function Giveaway() {
    const _signer = useSigner();
    const _provider = useProvider();
    const _account = useAccount()
    const _network = useNetwork();

    const [amountWon, setAmountWon] = useState(0)
    const [isClaimed, setIsClaimed] = useState(false)

    const [lastHash, setLastHash] = useState(undefined)
    const [amountTransfered, setAmountTransfered] = useState(undefined)

    const [retreived, setRetreived] = useState("Claim LENNY")
    
    const [isOpen, setIsOpen] = useState(false)
    const [isDeployed, setIsDeployed] = useState(false)

    const [ga, setGA] = useState(undefined)

    useEffect(() => {
        const init = async () => {
            if(_signer !== undefined && _provider !== undefined && _network !== undefined && _account !== undefined){
                
                var _cido;
                var _lenny;
                var _ga;

                if (_network.toString() === '56') {
                    _cido = SmartContractData.mainnet.cido
                    _lenny = SmartContractData.mainnet.lenny
                    _ga = SmartContractData.mainnet.ga
                    setGA(_ga)
                } else if (_network.toString() === '97') {
                    _cido = SmartContractData.testnet.cido
                    _lenny = SmartContractData.testnet.lenny
                    _ga = SmartContractData.testnet.ga
                    setGA(_ga)
                }
                
                
                const ga_smt = new ethers.Contract(_ga.address, _ga.abi, _provider)
                const cido_smt = new ethers.Contract(_cido.address, _cido.abi, _provider)

                setIsDeployed(await cido_smt.getDeployment())
                const upIsWinner = await ga_smt.giveaways(_signer.getAddress());

                if (upIsWinner.totalWon > 0) {
                    setAmountWon(numberWithCommas(upIsWinner.totalWon));
                    setIsClaimed(upIsWinner.TokenClaimed)
                }

                const lenny_smt = new ethers.Contract(_lenny.address, _lenny.abi, _provider)

                lenny_smt.on("Transfer", (from, to, amount, event) => {
                    if(from===_ga.address && to===_account){
                        setRetreived("Claim $LENNY ✅");
                        setIsOpen(true);
                        setLastHash(event.blockHash)
                        setAmountTransfered(amount)
                    }

                });
            }
        };

        init();

    }, [_signer, _provider, _account, _network]);


    const ClaimGiveaway = async (e) =>{
        try {
            e.preventDefault();
            setRetreived("Claim $LENNY ⏳");
            
            const ga_smt = new ethers.Contract(ga.address, ga.abi, _provider)
            const tx = await ga_smt.connect(_signer).retreiveGiveaway()
            
            setLastHash(await tx.wait())
            
        } catch (error) {
            setRetreived("Claim $LENNY");
            alert(error.data['message']);
        }
    }


    return (
        <>
            <Header />

            <div className="cido-container">
                    <div className="row row-custom">
                        <Col sm={8} lg={6} className="col-img-otr">
                                <div className="image-giveaway">
                                    <img className="give-img  animate-enter" src={giveaway} alt='giveaway' />
                                </div>
                        </Col>

                        <Col sm={8} lg={6} className="col-content-otr">
                            <div className="col-content-inr">
                                <div>
                                    <h2 className="give-title"> Claim your</h2>
                                    <h2 className="cido-title bk">
                                        <span className="gradient-text cido">$lenny</span>
                                    </h2>
                                </div>
                                <div className="give-info">
                                    <Row>
                                        <Col><Box title={amountWon + ' LENNY'} paragraph={'Amount Won'} /></Col>
                                        <Col><Box title={isClaimed.toString().toUpperCase()} paragraph={'Amount Claimed'} /></Col>

                                    </Row>
                                </div>

                            </div>

                            <div className='cont-function'>

                                    {isDeployed ?
                                        <GradientButton text={retreived} params={ClaimGiveaway} link={false} />
                                        :
                                        <GradientButton text={'Liquidity Not Deployed'} params={PopClaim} link={false} />
                                    }

                                </div>
                        </Col>
                    </div>
                </div>

            <Footer />

            <PopUp trigger={isOpen} setTrigger={setIsOpen} >

                <h3 className='popup-title'> Successful Transaction </h3>
                <br />
                <p> Claiming address : {_account} </p>
                <br />
                <p> Amount Claimed in decimals : {amountTransfered} </p>
                <br />
                <p> Tx Hash : {lastHash} </p>

            </PopUp>
        </>
    );
}

export default Giveaway;


const Box = (props) => {

    return (
        <div className="header-stat-box">
            <div className="text-box">
                <h3>
                    {props.title}
                </h3>

                <p className='p-box'>
                    {props.paragraph}
                </p>
            </div>
        </div>
    );
};

function PopClaim() {
    alert('Liquidity Pool Not Created Yet -- We need to reach our goal of 1,000,000 BUSD to raise');
}