import { React, useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import giveaway from '../../ImagesVideo/giveaway.png'

import GradientButton from '../Components/Buttons/Gradient/GradientButton'
import PopUp from '../Components/PopUp/PopUp'

import Header from '../Components/Header/Header'
import Footer from '../Components/Footer/Footer'

import {useSigner, useProvider, numberWithCommas, useAccount} from '../ConnectionContext'

import { SmartContractData } from '../../smartcontract/SmartContractData'

const token = SmartContractData.testnet.token
const {ethers} = require('ethers')
const ZeroAddress = '0x0000000000000000000000000000000000000000'

function Claim() {
    const _signer = useSigner();
    const _provider = useProvider();
    const _account = useAccount()

    const [value, setValue] = useState();
    const [isOpen, setIsOpen] = useState(false)

    function onChange(e) {
        if (!e.target.validity.patternMismatch) {
          setValue(e.target.value);
        }
    }

    const [contTokenBalance, setContTokenBalance] = useState(undefined)

    const [lastHash, setLastHash] = useState(undefined)

    const [claim, setClaim] = useState("Claim")

    useEffect(() => {
        const init = async () => {

            if(_signer !== undefined && _provider !== undefined){
                const decimal_bn = ethers.BigNumber.from(10).pow(18)

                const token_smt = new ethers.Contract(token.address, token.abi, _provider)
                const amount = ethers.BigNumber.from(await token_smt.balanceOf(_signer.getAddress())).div(decimal_bn)
                setContTokenBalance(numberWithCommas(amount))
                
                
                token_smt.on("Transfer", (from, to, amount, event) => {
                    if(from===ZeroAddress && to===_account){

                        const newBalance = parseInt(contTokenBalance) + parseInt(ethers.BigNumber.from(amount).div(decimal_bn))
                        setContTokenBalance(newBalance)
                        
                        setValue('')

                        setClaim("Claim ✅");
                        setLastHash(event.blockHash)
                        setIsOpen(true);

                        console.log(`Minting : ${amount.toString()}`)

                    }

                });
            }
        };

        init();

    }, [_signer, _provider, _account, contTokenBalance]);


    const ClaimToken = async (e) =>{
        try {
            if(value) {

            e.preventDefault();
            setClaim("Claim ⏳");
            
            const token_smt = new ethers.Contract(token.address, token.abi, _provider)
            const decimal_bn = ethers.BigNumber.from(10).pow(18)
            const mintToken = ethers.BigNumber.from(value).mul(decimal_bn)

            await token_smt.connect(_signer)._mint(_account, mintToken)

            
            }
            
        } catch (error) {
            setClaim("Claim");
            alert(error.data['message']);
        }
    }


    return (
        <>
        <Header />

            <div className="cido-container">
                <div className="row row-custom">
      

                    <Col sm={8} lg={6} className="col-content-otr">
                        <div className="col-content-inr">
                            <div>
                                <h2 className="give-title"> Claim your</h2>
                                <h2 className="cido-title bk">
                                    <span className="gradient-text cido">$Test</span>
                                </h2>
                            </div>
                            <div className="give-info">
                                <Row>
                                    <Col><Box title={contTokenBalance + ' TEST'} paragraph={'Current Balance'} /></Col>

                                </Row>
                            </div>

                        </div>

                        <div className='cont-function'>
                                <div className="input-box">
                                    <input className='input-value'
                                        value={value || ''}
                                        pattern="^[0-9]*$"
                                        onChange={onChange} />
                                </div>

                                <GradientButton text={claim} params={ClaimToken} link={false} />
                                        
                                </div>
                    </Col>


                    <Col sm={8} lg={6} className="col-img-otr">
                            <div className="image-giveaway">
                                <img className="give-img  animate-enter" src={giveaway} alt='giveaway' />
                            </div>
                    </Col>
                </div>
            </div>

        <Footer />

        <PopUp trigger={isOpen} setTrigger={setIsOpen} >

            <h3 className='popup-title'> Successful Transaction </h3>
            <br />
            <p> Claiming address : {_account} </p>
            <br />
            <p> Tx Hash : {lastHash} </p>

        </PopUp>
    </> 
    
    );
}

export default Claim;


const Box = (props) => {

    return (
        <div className="header-stat-box">
            <div className="text-box">
                <h3>
                    {props.title}
                </h3>

                <p className='p-box'>
                    {props.paragraph}
                </p>
            </div>
        </div>
    );
};



