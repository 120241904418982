import React from 'react';
import logo from '../../../ImagesVideo/LennyFaceLogo.webp';

import SocialButton from '../Buttons/Social/SocialButton';
import { FaDiscord, FaYoutube, FaGithub, FaTwitter } from 'react-icons/fa'
import { animateScroll as scroll } from 'react-scroll';

import "./Footer.css"


function Footer() {

    const toggleHome = () => {
        scroll.scrollToTop();
    }

    return (
        <>
            <div className='footer-container'>
                <div className='footer-wrap'>
                    <div className='footer-links-cont'>
                        <div className='footer-links-wrap'>
                            <div className='footer-text-wrap'>

                                <h1 className='footer-links-logo'>LennyVerse</h1>

                                <div className='footer-description'>
                                    Nobody really knows where Lenny comes from. Some say he was born from a cactus
                                    flower that bloomed in the middle of the desert while others speak of a tadpole
                                    that bathed in a drop of rain blessed by the moon gods. The truth is that we will
                                    never know the legendary origin of Lenny because he has no real past, only a
                                    destiny to fulfill.
                                </div>
                            </div>

                            <div className='footer-links-items'>

                                <h1 className='footer-links-title'> Ressources </h1>

                                <a className='footer-link' href="https://doc.lennyverse.io/" target="_blank" rel="noopener noreferrer">
                                    Documentation </a>

                                <a className='footer-link' href="https://www.lennyverse.io/whitepaper" target="_blank" rel="noopener noreferrer">
                                    White Paper </a>

                                <a className='footer-link' href="https://www.lennyverse.io/story" target="_blank" rel="noopener noreferrer">
                                    Lenny Story </a>

                            </div>

                            <div className='footer-links-items'>
                                <h1 className='footer-links-title'> Legals </h1>

                                <a className='footer-link' href="https://www.lennyverse.io/termsconditions" target="_blank" rel="noopener noreferrer">
                                    Terms & Conditions</a>

                                <a className='footer-link' href='https://www.lennyverse.io/policy' target="_blank" rel="noopener noreferrer">
                                    Privacy Policy</a>

                                <a className='footer-link' href='https://www.lennyverse.io/disclaimer' target="_blank" rel="noopener noreferrer">
                                    Risk Disclaimer</a>
                            </div>

                        </div>
                    </div>

                    <section className='socialmedia'>
                        <div className='socialmedia-wrap left'>
                            <div className='social-icons'>
                                <SocialButton icon={<FaDiscord />} params="https://discord.gg/ky7KkDrtgx" />
                                <SocialButton icon={<FaTwitter />} params="https://twitter.com/TheLennyVerse" />
                                <SocialButton icon={<FaYoutube />} params="https://www.youtube.com/channel/UCtBdEuUedfo5MFfL87VnlMQ" />
                                <SocialButton icon={<FaGithub />} params="https://github.com/LennyVerse" />
                            </div>
                        </div>
                        <div className='copyright-wrap right'>
                            <a className='social-logo' href="https://www.lennyverse.io/" target="_blank" rel="noopener noreferrer" onClick={toggleHome}>
                                <div className='footer-logo-cont'>
                                    <img className='footer-logo-image' src={logo} alt='logo' />
                                </div>
                            </a>

                            <small className='website-rights'>
                                LennyVerse © {new Date().getFullYear()} All rights reserved.
                            </small>
                        </div>


                    </section>

                </div>
            </div>

        </>
    )
}

export default Footer
