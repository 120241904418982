const token_t = require('./testnet/Token.json')
const dosc_t = require('./testnet/DemocraticOwnership.json')
const lock_t = require('./testnet/LennyTimelock.json')
const lenny_t = require('./testnet/Lenny.json')
const cido_t = require('./testnet/CommunityIDO.json')
const ga_t = require('./testnet/Giveaway.json')
const lennynft_t = require('./testnet/LennyNFT.json')
const market_t = require('./testnet/Market.json')

const conttoken = require('./mainnet/ContToken.json')
const dosc = require('./mainnet/DemocraticOwnership.json')
const lock = require('./mainnet/LennyTimelock.json')
const lenny = require('./mainnet/Lenny.json')
const cido = require('./mainnet/CommunityIDO.json')
const ga = require('./mainnet/Giveaway.json')


export const SmartContractData = {
    'networkTestnetId': '80001',
    'networkMainnetId': '137',
    'networkIdAllowed' : [137, 80001],
    'testnet': {
        'token': token_t,
        'dosc': dosc_t,
        'lock': lock_t,
        'lenny': lenny_t,
        'cido': cido_t,
        'ga': ga_t,
        'lennynft': lennynft_t,
        'market': market_t
    },
    'mainnet': {
        'conttoken': conttoken,
        'dosc': dosc,
        'lock': lock,
        'lenny': lenny,
        'cido': cido,
        'ga': ga,

    }
}

