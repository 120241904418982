import { React, useState, useEffect, useRef } from 'react';
import { FaWallet } from 'react-icons/fa';
import { FiUser, FiSettings, FiLogOut } from 'react-icons/fi';
import GradientButton from '../Buttons/Gradient/GradientButton';
import Avatar from '../../../ImagesVideo/avatar.svg';

import './Header.css';
import {
    useAccount,
    useMetamask,
    useConnection,
    useGoodNetwork,
    useAuthenticate,
    useNetwork
} from '../../ConnectionContext';
import { useDetectOutsideClick } from '../../useDetectOutsideClick';
import { SmartContractData } from '../../../smartcontract/SmartContractData';



const Hearder = () => {
    const _toConnect = useConnection()
    const _account = useAccount()
    const _metamaskInstalled = useMetamask()
    const _goodNetwork = useGoodNetwork()
    const _authenticated = useAuthenticate()

    const _network = useNetwork()

    const [connectedId, setConnectedId] = useState('')

    // Profile dropdown
    const dropdownRef = useRef(null);
    const [isActive, setIsActive] = useDetectOutsideClick(dropdownRef, false);
    const onClick = () => setIsActive(!isActive);
    console.log(onClick)

    useEffect(() => {

        if (_network !== undefined && _account !== undefined) {
            if (_network.toString() === SmartContractData.networkMainnetId) {
                setConnectedId('Mainnet')
            } else if (_network.toString() === SmartContractData.networkTestnetId) {
                setConnectedId('Testnet')
            }
        }

    }, [_network, _account]);
    console.log(connectedId)

    return (
        <div className="header">
            <div className="navigation">
                <li><a href="/" className="title logo">LennyVerse</a></li>

                <div className="menu">
                    <ul id="nav">
                        <li><a href="/">Discover</a></li>
                        <li><a href="/">Community</a></li>
                        <li><a href="/giveaway">Giveaway</a></li>
                    </ul>
                </div>

                {
                    _metamaskInstalled
                        ? _authenticated ?
                            _goodNetwork ?
                                <>
                                    <div className="user-profile">
                                        <img src={Avatar} alt="Profile" />
                                    </div>
                                    <nav
                                        ref={dropdownRef}
                                        className={`profile-menu ${isActive ? "active" : "inactive"}`}
                                    >
                                        <div className="profile-dropdown">
                                            <h3>Kala'i Shigetomi</h3>
                                            <div className="profile-info">
                                                <p>Balance</p><span>4.25 LENNY</span>
                                            </div>
                                            <div className="profile-info">
                                                <p>Wallet</p><span className="profile-wallet">0xAb5801a7D3...5B3259aeC9B</span>
                                            </div>
                                            <hr className="line"></hr>
                                            <ul>
                                                <li>
                                                    <a href="./"><FiUser /> My Profile</a>
                                                </li>
                                                <li>
                                                    <a href="./"><FiSettings /> Settings</a>
                                                </li>
                                                <li>
                                                    <a href="./"><FiLogOut /> Logout</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </nav>
                                </>


                                : <GradientButton text={'Wrong Network'}
                                    params={Pop} link={false} />

                            : <GradientButton
                                icon={<FaWallet size={20} />}
                                text={"Connect Wallet"}
                                params={_toConnect} link={false} />

                        : <GradientButton text={'Download Metamask'}
                            params='https://metamask.io/' link={true} />
                }

            </div>
        </div>
    );
};

export default Hearder;

function Pop() {
    alert('Wrong Network! Please connect to the appropriate Binance Smart Chain Network.');
}


