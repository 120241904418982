import { React } from 'react';
import "./GradientButton.css";

const GradientButton = (props) => {

    return (


        <div className="btn-container">
            {
                props.link ?
                    <a className="btn-basic btn-gradient" href={props.params} target="_blank" rel="noopener noreferrer">
                        <div className="icon"> {props.icon} </div> {props.text}
                    </a>
                    :
                    <div className="btn-basic btn-gradient" onClick={props.params}>
                        <div className="icon"> {props.icon} </div> {props.text}
                    </div>
            }
        </div>
    );
};

export default GradientButton;